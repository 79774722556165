import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import PaymentDetails from "../../components/Epharmacy/PaymentDetails"

export default ({ location }) => (
  <Layout
    title="Payment Details"
    subtitle="Select your preferred payment method."
    currentStep={4}
    totalSteps={4}
    process="epharmacy"
    seoTitle="Payment Details"
    display={{ footer: false, helpCenterBanner: false, radialStepper: true }}
    path={location?.pathname}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <PaymentDetails location={location} />
    </Container>
  </Layout>
)
