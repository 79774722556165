import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import PaymentMethodOptions from "./PaymentDetails/PaymentMethodOptions"

import { AppContext } from "../../context/AppContext"
import { isObjectEmpty } from "../../services/general"
import { getContextFromSession } from "../../context/services/context"

import {
  accumulatePrice,
  generateDeliveryFee,
  accumulateVat,
} from "./services/computations"
import { getCouponDiscountAmount } from "./services/coupon"
import specialCoupons from "./utils/specialCoupons.json"
import { paymentDetailsValidationSchema } from "./utils/formData"

const PaymentDetails = ({ location }) => {
  const { state, dispatch } = useContext(AppContext)
  let formValues = getContextFromSession()
  if (isObjectEmpty(formValues)) formValues = state
  const isNationwide =
    state?.epharmacy?.deliveryAddress?.province?.value !== "Metro Manila"

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const { medicines, coupon } = state["epharmacy"]
  const couponDiscount = getCouponDiscountAmount(
    coupon,
    medicines,
    generateDeliveryFee({ couponCode: coupon?.code })
  )

  const cartTotal = (
    accumulatePrice(medicines) +
    generateDeliveryFee({ couponCode: coupon?.code }) -
    (specialCoupons.includes(coupon?.code) ? accumulateVat(medicines) : 0) -
    couponDiscount
  ).toFixed(2)

  return (
    <Formik
      initialValues={{
        ...formValues.epharmacy,
        paymentOption: isNationwide
          ? "Bank Transfer/Deposit - UnionBank"
          : formValues?.epharmacy?.paymentOption || "Cash on Delivery",
      }}
      onSubmit={async (values) => {
        if (
          values?.paymentOption !== "Bank Transfer/Deposit - UnionBank" &&
          values?.changeFor < cartTotal
        )
          return values
        await dispatch({
          type: "SAVE_CONTEXT_TO_SESSION",
          payload: {
            ...state,
            epharmacy: { ...state.epharmacy, ...values },
          },
        })
        await dispatch({ type: "SAVE_DELIVERY_DETAILS", payload: values })
        navigate("/epharmacy/summary")
      }}
      validationSchema={paymentDetailsValidationSchema}
      validateOnMount={true}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Section className="my-1">
            <PaymentMethodOptions
              values={values}
              setFieldValue={setFieldValue}
              isNationwide={isNationwide}
              cartTotal={cartTotal}
            />
          </Section>

          <ActionButtons
            submit={{
              label: location?.state?.previousRoute
                ? "Summary"
                : "Review Order",
              disabled: !values.paymentOption,
            }}
            back={{ label: "Back", link: "/epharmacy/checkout" }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default PaymentDetails
