import React from "react"
import classNames from "classnames"
import FormInput from "../../Elements/Form/FormInput"
import FormRadio from "../../Elements/Form/FormRadio"

export const generateFollowUp = ({
  values,
  followUp,
  cartTotal,
  selectedPaymentOption,
}) => {
  let isCOD = selectedPaymentOption === "Cash on Delivery"
  let inputHelperText = isCOD ? `Order Total: ${cartTotal}` : ""
  switch (followUp.followUpType) {
    case "input":
      return (
        <FormInput
          {...followUp}
          helper={inputHelperText}
          addonLeft={
            followUp?.hasAddons && (
              <p class="control">
                <span class="button is-static">{followUp?.addonLeft}</span>
              </p>
            )
          }
        />
      )
    case "message":
      return (
        <p className={classNames("is-size-6", followUp.className || "")}>
          {followUp?.message}
        </p>
      )
    case "radio":
      return <FormRadio value={values?.[followUp?.name]} {...followUp} />
    default:
      return null
  }
}
